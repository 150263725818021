<template>
    <div class="view">
        <div class="orderInfo" v-if="orderInfo">
            <div class="infoLi">
                <div class="label">救援师傅：</div>
                <div class="value">{{ orderInfo.driver.nick }}</div>
            </div>
            <div class="infoLi">
                <div class="label">客户名称：</div>
                <div class="value">{{ orderInfo.contact }}</div>
            </div>
            <div class="infoLi">
                <div class="label">客户电话：</div>
                <div class="value">{{ orderInfo.phone }}</div>
            </div>
            <div class="infoLi">
                <div class="label">被救车牌号：</div>
                <div class="value">{{ orderInfo.car_no }}</div>
            </div>
            <div class="infoLi">
                <div class="label">被救车架号（后六位）：</div>
                <div class="value">{{ orderInfo.vin | filterSix }}</div>
            </div>
            <div class="infoLi">
                <div class="label">接单日期：</div>
                <div class="value">{{ orderInfo.depart_time | filterTime }}</div>
            </div>
            <div class="infoLi">
                <div class="label">作业地点：</div>
                <div class="value">{{ orderInfo.rescue_address }}</div>
            </div>
        </div>
        <div id="pdf" class="pdfBox">
            <div class="sign" @click="toSign" >
                <img v-if="signUrl" class="signUrl" :src="signUrl" alt=""/>
                <span v-else>点击签名</span>
            </div>
        </div>
        <div class="footer" @click="submitSign">
            提交签署
        </div>
        <!-- 签名 -->
        <Sign 
            v-if="signVsi" 
            class="signView"
            @close='signVsi = false' 
            @success="signSuccess"
            :signKey='signKey'
            :orderId="order"/>
    </div>
</template>

<script>
import Pdfh5 from "pdfh5";  // 这两个一定要引入
import "pdfh5/css/pdfh5.css"; // 这两个一定要引入， 这个是在加载时，顶部会出来一个加载进度条和一些其他的样式
import { formatTime } from '@/utils/index';
import { uploadPicture } from '@/apis/checkList';
import Sign from '@/components/checkList/sign';

export default {
    name: 'signFile',
    data(){
        return {
            order: '',
            orderInfo: '',
            pdfh5: '',
            signVsi: false,
            signKey: 'agreement',
            signUrl: '',        //https://rescue-test.oss-cn-shenzhen.aliyuncs.com/2023-05-06/h5_pwaPHDRXbzPaWEpzhG3i2bn7A2cYykG3.png
        }
    },
    components: {
        Sign
    },
    mounted(){
        this.initPdf()
        this.orderInfo = this.$route.params.orderInfo;
        this.order = this.$route.params.order;
    },
    methods: {
        initPdf(){
            this.pdfh5 = new Pdfh5("#pdf", {
                pdfurl: "/static/mzxy.pdf", // pdf 地址，请求的地址需要为线上的地址，测试的本地的地址是不可以的
                lazy: true, // 是否懒加载
                withCredentials: true,
                renderType: "svg",
                maxZoom: 3, //手势缩放最大倍数 默认3
                scrollEnable: true, //是否允许pdf滚动
                zoomEnable: false, //是否允许pdf手势缩放
            });
        },
        toSign(){
            this.signVsi = true;
        },
        signSuccess(url){
            console.log(url, '签名成功')
            this.signUrl = url;
            this.signVsi = false;
        },
        submitSign(){
            if(!this.signUrl){
                this.$toast('请先签名再上传！');
                return
            }
            let data = {
                order: this.order,
                photo: this.signUrl,
                key: 'sign'
            }
            uploadPicture(data).then((res)=>{
                console.log(res)
                if(res.data.code != 0){
                    this.$toast('签署成功');
                    this.$router.go(-1);
                }else{
                    this.$toast(res.data.info);
                }
            })
        }
    },
    filters: {
        filterSix(val){
            return val ? val.substr(-6) : '-';
        },
        filterTime(val){
			if(val){
				return formatTime(val, 'Y-M-D')
			}
		},
    }
}
</script>

<style lang="scss" scoped>
    .orderInfo{
        width: 345px;
        margin: 10px auto;
        font-size: 14px;
        .infoLi{
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            .label{
                color: #666;
            }
        }
    }
    .pdfBox{
        position: relative;
        .sign{
            position: absolute;
            width: 200px;
            height: 50px;
            border: 1px solid #e4e4e4;
            bottom: 165px;
            right: 30px;
            color: red;
            line-height: 50px;
            text-align: center;
            z-index: 9;
        }
    }
    .footer{
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        height: 50px;
        background: red;
        color: #fff;
        text-align: center;
        line-height: 50px;
        z-index: 99;
    }
    .signView{
        z-index: 999;
    }
    .signUrl{
        width: 100%;
        height: 100%;
    }
</style>